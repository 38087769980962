<template>
  <div class="px-4 pt-4">
    <div class="mb-4">
      <a-button
        type="primary"
        class="flex items-center"
        @click="showModal('add', 0)"
        >添加</a-button
      >
      <!-- <a-button type="danger" class="flex items-center ml-4">删除</a-button> -->
    </div>
    <a-table
      :columns="columns"
      :data-source="data"
      :scroll="{ x: 1000 }"
      :pagination="pagination"
      @change="pageChange"
    >
      <template slot="sort" slot-scope="text, record">
        <editable-cell :text="text" @change="onCellChange(record, $event)" />
      </template>
      <template slot="status" slot-scope="text, record">
        <div class="flex">
          <a-switch :checked="record.status" @change="onChange(record)" />
        </div>
      </template>
      <template slot="operation" slot-scope="text, record">
        <div class="flex">
          <a-button type="default" class="mr-2" @click="addChildrenData(record)"
            >添加下级</a-button
          >
          <a-button type="primary" class="mr-2" @click="modifyData(record)"
            >编辑</a-button
          >
          <a-popconfirm
            title="确定删除该项标签吗?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="deleteData(record)"
          >
            <a-button type="danger" class="mr-2">删除</a-button>
          </a-popconfirm>
        </div>
      </template>
    </a-table>
    <a-modal
      :title="title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :footer="false"
      @cancel="visible = false"
    >
      <div>
        <a-form :form="form">
          <a-form-item
            :label-col="formItemLayout.labelCol"
            :wrapper-col="formItemLayout.wrapperCol"
            label="名称"
          >
            <a-input
              v-decorator="[
                'name',
                {
                  rules: [{ required: true, message: '请输入标签名称' }],
                },
              ]"
              placeholder="请输入标签名称"
            />
          </a-form-item>
          <a-form-item
            :label-col="formItemLayout.labelCol"
            :wrapper-col="formItemLayout.wrapperCol"
            label="排序"
          >
            <a-input
              v-decorator="[
                'sort',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入序号',
                    },
                  ],
                },
              ]"
              placeholder="请输入序号"
              type="number"
            />
          </a-form-item>
          <a-form-item
            :label-col="formTailLayout.labelCol"
            :wrapper-col="formTailLayout.wrapperCol"
          >
            <a-button
              type="primary"
              @click="check"
              size="large"
              style="width:200px"
            >
              {{ type == "add" ? "添加" : "编辑" }}
            </a-button>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>
<script>
import {
  tagList,
  tagDisable,
  tagInfo,
  tagAdd,
  tagEdit,
  tagDele,
} from "@/api/tags";
const EditableCell = {
  template: `
      <div class="editable-cell">
        <div v-if="editable" class="editable-cell-input-wrapper">
          <a-input :value="value" @change="handleChange" @pressEnter="check" /><a-icon
            type="check"
            class="editable-cell-icon-check"
            @click="check"
          />
        </div>
        <div v-else class="editable-cell-text-wrapper">
          {{ value || ' ' }}
          <a-icon type="edit" class="editable-cell-icon" @click="edit" />
        </div>
      </div>
    `,
  props: {
    text: Number,
  },
  data() {
    return {
      value: this.text,
      editable: false,
    };
  },

  methods: {
    handleChange(e) {
      const value = e.target.value;
      this.value = value;
    },
    check() {
      this.editable = false;
      this.$emit("change", this.value);
    },
    edit() {
      this.editable = true;
    },
  },
};
const columns = [
  {
    title: "名称",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "排序",
    dataIndex: "sort",
    width: 200,
    key: "sort",
    sorter: (a, b) => a.sort - b.sort,
    scopedSlots: { customRender: "sort" },
  },
  {
    title: "状态",
    dataIndex: "stuatus",
    // fixed: "right",
    with: 200,
    scopedSlots: { customRender: "status" },
  },
  {
    title: "操作",
    key: "operation",
    fixed: "right",
    scopedSlots: { customRender: "operation" },
  },
];

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};
const formTailLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18, offset: 6 },
};

export default {
  data() {
    return {
      data: [],
      columns,
      expandedRowKeys: [],
      visible: false,
      confirmLoading: false,
      title: "",
      pagination: {
        // 关于页码的配置属性
        total: 0,
        defaultPageSize: 7,
      },
      page: 1,
      checkNick: false,
      formItemLayout,
      formTailLayout,
      form: this.$form.createForm(this, { name: "dynamic_rule" }),
      pid: "", // 存储添加标签的父级标签id
      id: "", // 存储修改时的标签id
      type: "",
    };
  },
  created() {
    this.getList();
  },
  components: {
    EditableCell,
  },
  methods: {
    onChange(record) {
      tagDisable({
        id: record.id,
        is_disable: record.is_disable == 0 ? 1 : 0,
      }).then((res) => {
        if (res.code == 200) {
          this.$message.success("操作成功");
          this.getList();
        } else {
          this.$message.error("操作失败，请稍后再试");
        }
      });
    },
    // 添加标签
    addData(data) {
      tagAdd({
        ...data,
        pid: this.pid,
      }).then((res) => {
        if (res.code == 200) {
          this.$message.success("操作成功");
          this.page = 1;
          this.getList();
          this.visible = false;
        } else {
          this.$message.error("操作失败，请稍后再试");
        }
      });
    },
    // 添加下级标签
    addChildrenData(record) {
      this.type = "add";
      console.log(record);
      this.title = "添加下级标签";
      this.pid = record.id;
      this.visible = true;
    },
    // 点击修改按钮触发的方法
    modifyData(record) {
      this.pid = record.pid;
      this.id = record.id;

      this.title = "编辑标签";
      this.visible = true;
      this.type = "modify";
      this.$nextTick(() => {
        this.form.setFieldsValue({
          name: record.name,
          sort: record.sort,
        });
      });
    },
    // 修改标签
    modifyDataRequest(data) {
      tagEdit({
        ...data,
        pid: this.pid,
        id: this.id,
      }).then((res) => {
        if (res.code == 200) {
          this.$message.success("修改成功");
          this.page = 1;
          this.getList();
          this.visible = false;
        } else {
          this.$message.error("操作失败，请稍后再试");
        }
      });
    },
    // 删除标签
    deleteData(record) {
      tagDele({
        id: record.id,
      }).then((res) => {
        if (res.code == 200) {
          this.$message.success("标签删除成功");
          this.getList();
        } else {
          this.$message.error("删除失败，请稍后再试");
        }
      });
    },
    // 打开弹窗
    showModal(type, pid) {
      this.pid = pid;
      type == "add" ? (this.title = "添加标签") : (this.title = "修改标签");
      this.type = type;
      this.visible = true;
    },
    // 表单校验
    check() {
      this.form.validateFields((err) => {
        if (!err) {
          this.type == "add"
            ? this.addData(this.form.getFieldsValue())
            : this.modifyDataRequest(this.form.getFieldsValue());
        }
      });
    },
    handleCancel(e) {
      this.visible = false;
    },
    // 页码改变时
    pageChange(e) {
      this.page = e.current;
      this.getList();
    },
    // 获取列表
    getList() {
      tagList({
        page: this.page,
        limit: this.pagination.defaultPageSize,
      }).then((res) => {
        if (res.code == 200) {
          let { list, count } = res.data;
          this.pagination.total = count;
          this.addKey(list);
          this.data = [];
          this.data.push(...list);
        }
      });
    },
    // 添加key
    addKey(list) {
      list.forEach((item) => {
        item.key = item.id;
        item.status = item.is_disable ? false : true;
        if (item.children) {
          this.addKey(item.children);
        }
      });
    },
    // 表格修改序号触发的事件
    onCellChange(record, e) {
      this.pid = record.pid;
      this.id = record.id;
      this.modifyDataRequest({
        name: record.name,
        sort: e,
      });
    },
  },
};
</script>
<style lang="less" scoped></style>
