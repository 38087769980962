var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-4 pt-4"},[_c('div',{staticClass:"mb-4"},[_c('a-button',{staticClass:"flex items-center",attrs:{"type":"primary"},on:{"click":function($event){return _vm.showModal('add', 0)}}},[_vm._v("添加")])],1),_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.data,"scroll":{ x: 1000 },"pagination":_vm.pagination},on:{"change":_vm.pageChange},scopedSlots:_vm._u([{key:"sort",fn:function(text, record){return [_c('editable-cell',{attrs:{"text":text},on:{"change":function($event){return _vm.onCellChange(record, $event)}}})]}},{key:"status",fn:function(text, record){return [_c('div',{staticClass:"flex"},[_c('a-switch',{attrs:{"checked":record.status},on:{"change":function($event){return _vm.onChange(record)}}})],1)]}},{key:"operation",fn:function(text, record){return [_c('div',{staticClass:"flex"},[_c('a-button',{staticClass:"mr-2",attrs:{"type":"default"},on:{"click":function($event){return _vm.addChildrenData(record)}}},[_vm._v("添加下级")]),_c('a-button',{staticClass:"mr-2",attrs:{"type":"primary"},on:{"click":function($event){return _vm.modifyData(record)}}},[_vm._v("编辑")]),_c('a-popconfirm',{attrs:{"title":"确定删除该项标签吗?","ok-text":"确定","cancel-text":"取消"},on:{"confirm":function($event){return _vm.deleteData(record)}}},[_c('a-button',{staticClass:"mr-2",attrs:{"type":"danger"}},[_vm._v("删除")])],1)],1)]}}])}),_c('a-modal',{attrs:{"title":_vm.title,"visible":_vm.visible,"confirm-loading":_vm.confirmLoading,"footer":false},on:{"cancel":function($event){_vm.visible = false}}},[_c('div',[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'name',
              {
                rules: [{ required: true, message: '请输入标签名称' }],
              },
            ]),expression:"[\n              'name',\n              {\n                rules: [{ required: true, message: '请输入标签名称' }],\n              },\n            ]"}],attrs:{"placeholder":"请输入标签名称"}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"排序"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'sort',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入序号',
                  },
                ],
              },
            ]),expression:"[\n              'sort',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '请输入序号',\n                  },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"请输入序号","type":"number"}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formTailLayout.labelCol,"wrapper-col":_vm.formTailLayout.wrapperCol}},[_c('a-button',{staticStyle:{"width":"200px"},attrs:{"type":"primary","size":"large"},on:{"click":_vm.check}},[_vm._v(" "+_vm._s(_vm.type == "add" ? "添加" : "编辑")+" ")])],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }